import { Box, Divider } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { Close } from 'mdi-material-ui'
import { StyledDialogHeader } from './CustomDialog.styles'
import StyleGuide from '../../styles/StyleGuide'
import { FlexColumn } from '../../styles/containers/FlexContainers'

interface IProps {
  isOpen: boolean
  handleClose: () => void
  title?: string
  subtitle?: string
  children: React.ReactNode
  buttons?: React.ReactNode
}

const CustomDialog = ({ isOpen, handleClose, title, subtitle, children, buttons }: IProps) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        PaperProps={{ sx: { padding: '40px' } }}
      >
        <FlexColumn gap="32px">
          <StyledDialogHeader sx={{ alignItems: 'flex-start' }}>
            <DialogTitle
              sx={{
                padding: 0,
                fontWeight: '700',
                fontSize: '1.5rem',
                lineHeight: '2rem',
                color: StyleGuide.constants.COLOR_TEXT_PRIMARY
              }}
            >
              {title}
            </DialogTitle>
            <Button onClick={handleClose}>
              <Close sx={{ color: StyleGuide.constants.COLOR_TEXT_PRIMARY }} />
            </Button>
          </StyledDialogHeader>
          <DialogContent sx={{ padding: 0, whiteSpace: 'pre-line'}}>
            <Box>
              <DialogContentText>{subtitle}</DialogContentText>
              {children}
            </Box>
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <DialogActions sx={{ display: 'block', padding: 0 }}>{buttons}</DialogActions>
          </DialogContent>
        </FlexColumn>
      </Dialog>
    </>
  )
}

export default CustomDialog
